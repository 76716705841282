import { useMutation } from '@apollo/client';
import Router from 'next/router';
import { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { CREATE_CUSTOMER, CUSTOMER_LOGIN, GET_CUSTOMER, apolloClient } from '../shopify/apollo';
import { getCookie, removeCookies, setCookies } from 'cookies-next';

export interface AuthContextValue {
  createCustomer: (customer: any) => Promise<void>;
  login: (email: string, password: string) => Promise<string>;
  fetchCustomer: (customerToken: string) => void;
  logout: () => void;
  customer?: any;
}

export const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export const AuthProvider = ({ children }) => {
  const [customer, setCustomer] = useState<any>();
  const [createCustomerMutation] = useMutation(CREATE_CUSTOMER);
  const [customerLoginMutation] = useMutation(CUSTOMER_LOGIN);

  const fetchCustomer = useCallback(async (customerToken: string) => {
    try {
      const { data } = await apolloClient.query({ query: GET_CUSTOMER, variables: { customerToken } });
      if (data?.customer) {
        data?.customer && setCustomer({ ...data.customer, token: customerToken });
      } else {
        logout();
      }
    } catch (e) {
      console.log(e);
      logout();
    }
  }, []);

  const createCustomer = async (customer: any) => {
    const { data } = await createCustomerMutation({ variables: { 
      input: { 
        email: customer.email, 
        password: customer.password,
        firstName: customer.firstName,
        lastName: customer.lastName,
        acceptsMarketing: true
      }
    } });
    const errors = data?.customerCreate?.customerUserErrors;
    if (errors.length) {
      return errors;
    } else {
      return data;
    }
  };

  const login = async (email: string, password: string) => {
    const { data } = await customerLoginMutation({ variables: { input: { email, password } } });
      
    const errors = data?.customerAccessTokenCreate?.customerUserErrors?.length;
    if (errors) {
      throw Error('Ha ocurrido un error al iniciar sesión');
    }

    const token = data?.customerAccessTokenCreate?.customerAccessToken?.accessToken;
    if (token) {
      setCookies('userToken', JSON.stringify(token));
      fetchCustomer(token);
      return token;
    }
  };

  const logout = () => {
    removeCookies('userToken');
    //window?.localStorage.removeItem('checkout');
    removeCookies('checkout');
    setCustomer(null);
    Router.push('/');
  };
  
  useEffect(() => {
    const token = getCookie('userToken');
    token && fetchCustomer(JSON.parse(token as string));
  }, [fetchCustomer]);

  return <AuthContext.Provider value={{ 
    fetchCustomer,
    customer, 
    createCustomer, 
    login, 
    logout 
  }}>
    {children}
  </AuthContext.Provider>;
};

export const useAuth = (): AuthContextValue => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useCheckout must be used within a checkoutProvider');
  }
  return context;
};
