import '../styles/globals.css';
import '../components/cookieConsent/package/cookieconsent.css';
import type { AppProps } from 'next/app';
import { setConfiguration } from 'react-grid-system';
import { appWithTranslation } from 'next-i18next';
import { CheckoutProvider } from '../contexts/CheckoutContext';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from '../shopify/apollo';
import { AuthProvider } from '../contexts/AuthContext';
import { Toaster } from 'react-hot-toast';
import { AnalyticsProvider } from '../contexts/AnalyticsContext';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import * as fbq from '../components/facebook/FBPixel';
import Script from 'next/script';
const CookiesBanner = dynamic(() => import('../components/cookieConsent/CookiesBanner'), { ssr: false });

function Abokados({ Component, pageProps }: AppProps) {
  const defaultScreenClass = 'xs';
  const router = useRouter();
  
  setConfiguration({ 
    maxScreenClass: 'xl', 
    defaultScreenClass: defaultScreenClass ?? 'xs'
  });

  useEffect(() => {
    fbq.pageview();
    const handleRouteChange = () => fbq.pageview();

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => 
      router.events.off('routeChangeComplete', handleRouteChange);
  }, [router.events]);
  
  return <ApolloProvider client={apolloClient}>
    <AuthProvider>
      <CheckoutProvider>
        <AnalyticsProvider>
          <Script
            id="fb-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${fbq.FB_PIXEL_ID});
              `
            }}
          />
          <Component {...pageProps}/>
          <Toaster/>
          <CookiesBanner/>
        </AnalyticsProvider>
      </CheckoutProvider>
    </AuthProvider>
  </ApolloProvider>;
}

export default appWithTranslation(Abokados);
