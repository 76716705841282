import styled from '@emotion/styled';
import type { NextPage } from 'next';
import { Container } from 'react-grid-system';
import { Logo } from '../components/logo/Logo';

const CustomContainer = styled(Container)`
  padding: 45px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  margin: 20px auto 0 auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;

  h2, p {
    padding: 0;
    margin: 0;
  }
`;

const Home: NextPage = () => {
  return <CustomContainer>
    <Logo height={40}/>
    <Content>
      <h2>Cerrado temporalmente</h2>
      <p>Agradecemos de corazón todo el apoyo y cariño que nos habéis brindado a lo largo de este viaje. Ha sido un honor endulzar vuestros momentos especiales. ¡Gracias por acompañarnos en esta aventura!</p>
    </Content>
  </CustomContainer>;
};

export default Home;
