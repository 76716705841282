import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Shopify } from '../shopify/client';
import { useAuth } from './AuthContext';
import { useMutation } from '@apollo/client';
import { CHECKOUT_CUSTOMER_ASSOCIATE } from '../shopify/apollo';
import { mapDefaultAddress } from '../helpers/user.helper';
import { Address } from '../shopify/types';
import { setCookies, removeCookies, getCookie } from 'cookies-next';

export interface CheckoutContextValue {
  checkout?: any;
  addLineItems: (lineItems) => Promise<void>;
  updateLineItems: (lineItems) => Promise<void>;
  removeLineItems: (lineItems) => Promise<void>;
  updateCheckout?: () => Promise<void>;
  updateCheckoutEmail: (email: string) => Promise<void>;
  updateShippingAddress: (shippingAddress: Address) => Promise<any>;
  addDiscount?: () => Promise<void>;
  removeDiscount?: () => Promise<void>;
  addGiftcards?: () => Promise<void>;
  removeGiftcard?: () => Promise<void>;
  refetch: (checkoutId: string) => Promise<void>;
  AddDiscountToCheckout: (discountCode: string) => Promise<string | undefined>;
  updateCheckoutCustomer: (token: string) => Promise<any>;
}

export const CheckoutContext = createContext<CheckoutContextValue | undefined>(undefined);

export const CheckoutProvider = ({ children }) => {
  const [updateCheckoutCustomerMutation] = useMutation(CHECKOUT_CUSTOMER_ASSOCIATE);
  const [checkout, setCheckout] = useState<any>();
  const [checkoutId, setCheckoutId] = useState<any>();
  const { customer } = useAuth();

  const createCheckout = useCallback(async () => {
    const checkout = await Shopify.createCheckout();
    setCheckout(checkout);
    setCookies('checkout', checkout.id, { expires: new Date(Date.now() + (3600 * 1000 * 24)) });
  }, []);

  const resetCheckout = useCallback(async () => {
    removeCookies('checkout');
    setCheckout(null);
    await createCheckout();
  }, [createCheckout]);

  const refetchCheckout = useCallback(async (checkoutId) => {
    if (checkoutId) {
      try {
        const checkout = await Shopify.getCheckoutById(checkoutId);
        setCheckout(checkout);
        
        if (checkout.order) {
          resetCheckout();
        }

        console.log(checkout);
      } catch (e) {
        console.log(e);
        resetCheckout();
      }
    }
  }, [resetCheckout]);

  const addLineItems = async (lineItems) => {
    if (checkout) {
      await Shopify.addLineItemsToCheckout(checkout.id, lineItems);
      await refetchCheckout(checkout.id);
      return;
    }
    throw Error('No se pueden añadir los productos');
  };

  const updateLineItems = async (lineItems) => {
    if (checkout) {
      await Shopify.updateLineItemsFromCheckout(checkout.id, lineItems);
      await refetchCheckout(checkout.id);
      return;
    }
    throw Error('No se pueden añadir los productos');
  };

  const removeLineItems = async (lineItems) => {
    if (checkout) {
      await Shopify.removeLineItemsFromCheckout(checkout.id, lineItems);
      await refetchCheckout(checkout.id);
      return;
    }
    throw Error('No se pueden añadir los productos');
  };

  const updateCheckoutEmail = async (email: string) => {
    if (checkout) {
      await Shopify.updateCheckoutEmail(checkout.id, email);
      await refetchCheckout(checkout.id);
      return;
    }
    throw Error('No se puede añadir el email');
  };

  const updateCheckoutCustomer = useCallback(async (customerAccessToken) => {
    if (checkout) {
      return await updateCheckoutCustomerMutation({ variables: { checkoutId: checkout.id, customerAccessToken } });
    }
  }, [checkout, updateCheckoutCustomerMutation]);

  const updateShippingAddress = useCallback(async (shippingAddress: Address) => {
    return Shopify.updateShippingAddressFromCheckout(checkout.id, shippingAddress);
  }, [checkout?.id]);

  const AddDiscountToCheckout = useCallback(async (discountCode: string) => {
    if (checkout) {
      try {
        const response: any = await Shopify.addDiscountToCheckout(checkout.id, discountCode);
        if (response?.userErrors?.length) {
          throw Error('Cupón no válido');
        } else {
          refetchCheckout(checkout.id);
          return 'Cupón válido';
        }
      } catch (e) {
        throw Error('Cupón no válido');
      }
    }
  }, [checkout, refetchCheckout]);
  
  useEffect(() => {
    let checkoutCookie = getCookie('checkout');
    if (checkoutCookie) {
      setCheckoutId(checkoutCookie);
      refetchCheckout(checkoutCookie);
    } else {
      createCheckout();
    } 
  }, [refetchCheckout, createCheckout, customer]);

  useEffect(() => {
    (async () => {
      try {
        if (customer && checkout && !checkout.email) {
          await updateCheckoutCustomerMutation({ variables: { checkoutId: checkout.id, customerAccessToken: customer.token } });
          if (customer.defaultAddress) {
            const shippingAddress = mapDefaultAddress(customer.defaultAddress, customer);
            await updateShippingAddress(shippingAddress);
          }
          refetchCheckout(checkout?.id);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [customer, checkout, refetchCheckout, updateCheckoutCustomerMutation, updateShippingAddress]);

  useEffect(() => {
    if (checkoutId === 'undefined') {
      removeCookies('checkout');
    }
  }, [checkoutId]);

  return <CheckoutContext.Provider value={{ 
    checkout, 
    refetch: refetchCheckout, 
    addLineItems, 
    updateCheckoutEmail, 
    removeLineItems, 
    updateLineItems,
    AddDiscountToCheckout,
    updateCheckoutCustomer,
    updateShippingAddress
  }}>
    {children}
  </CheckoutContext.Provider>;
};

export const useCheckout = (): CheckoutContextValue => {
  const context = useContext(CheckoutContext);
  if (context === undefined) {
    throw new Error('useCheckout must be used within a checkoutProvider');
  }
  return context;
};
