import { Address } from '../shopify/types';

export const mapDefaultAddress = (address: any, customer: any): Address => {
  return {
    address1: address?.address1,
    address2: address?.address2,
    city: address?.city,
    company: address?.compnay,
    country: address?.country,
    firstName: address?.firstName || customer.firstName,
    lastName: address?.lastName || customer.lastName,
    phone: address?.phone || customer.phone,
    province: address?.province,
    zip: address?.zip
  };
};
