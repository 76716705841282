

export const FB_PIXEL_ID = 531254595548147;

export const pageview = () => {
  (window as any).fbq('track', 'PageView');
};

export const viewContent = () => {
  (window as any).fbq('track', 'ViewContent');
};

export const event = (name, options = {}) => {
  (window as any).fbq('track', name, options);
};
