import Client from 'shopify-buy/index.unoptimized.umd';
import { ShopifyProduct } from './types';

export const ShopifyClient = Client.buildClient({
  domain: process.env.NEXT_PUBLIC_SHOPIFY_URL,
  storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_KEY,
  language: 'es-ES'
});

const shippingPolicy = ShopifyClient.graphQLClient.query((root) => {
  root.add('shop', (shop) => {
    shop.add('shippingPolicy', (policy) => {
      policy.add('id');
      policy.add('title');
      policy.add('body');
      policy.add('url');
    });
  });
});

export const Shopify = {
  getAllProducts: async (): Promise<ShopifyProduct[]> => {
    try {
      const products = await ShopifyClient.product.fetchAll();
      return JSON.parse(JSON.stringify(products));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  getProductById: async (productId: string): Promise<ShopifyProduct> => {
    try {
      const products = await ShopifyClient.product.fetch(productId);
      return JSON.parse(JSON.stringify(products));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  getProductByHandle: async (handle: string): Promise<ShopifyProduct> => {
    try {
      const product = await ShopifyClient.product.fetchByHandle(handle);
      return JSON.parse(JSON.stringify(product));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  getProductsByQuery: async (query: string): Promise<ShopifyProduct> => {
    try {
      const products = await ShopifyClient.product.fetchQuery({ query });
      return JSON.parse(JSON.stringify(products));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  getCollections: async (): Promise<any> => {
    try {
      const collections = await ShopifyClient.collection.fetchAll();
      return JSON.parse(JSON.stringify(collections));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  getAllCollectionsWithProducts: async (): Promise<any> => {
    try {
      const collections = await ShopifyClient.collection.fetchAllWithProducts();
      return JSON.parse(JSON.stringify(collections));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  getCollectionById: async (collectionId: string): Promise<any> => {
    try {
      const collection = await ShopifyClient.collection.fetchWithProducts(collectionId);
      return JSON.parse(JSON.stringify(collection));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  getCollectionByHandle: async (handle: string): Promise<any> => {
    try {
      const collection = await ShopifyClient.collection.fetchByHandle(handle);
      return JSON.parse(JSON.stringify(collection));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  getShopInfo: async (): Promise<any> => {
    try {
      const shop = await ShopifyClient.shop.fetchInfo();
      return JSON.parse(JSON.stringify(shop));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  getShopPolicies: async (): Promise<any> => {
    try {
      const policies = await ShopifyClient.shop.fetchPolicies();
      const shippingPolicyQuery = await ShopifyClient.graphQLClient.send(shippingPolicy);
      const shippingPolicyData = shippingPolicyQuery.data.shop.shippingPolicy;
      const allData = { ...policies, shippingPolicy: shippingPolicyData };
      return JSON.parse(JSON.stringify(allData));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  getCheckoutById: async (checkoutId: string): Promise<any> => {
    try {
      const checkout = await ShopifyClient.checkout.fetch(checkoutId);
      return JSON.parse(JSON.stringify(checkout));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  createCheckout: async (input = {}): Promise<any> => {
    try {
      const checkout = await ShopifyClient.checkout.create(input);
      return JSON.parse(JSON.stringify(checkout));
    } catch (e) {
      throw Error('Ha ocurrido un error al crear tu carrito');
    }
  },
  updateCheckoutAttributes: async (checkoutId: string, attributes): Promise<any> => {
    try {
      const checkout = await ShopifyClient.checkout.updateAttributes(checkoutId, attributes);
      return JSON.parse(JSON.stringify(checkout));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  updateCheckoutEmail: async (checkoutId: string, email: string): Promise<any> => {
    try {
      const checkout = await ShopifyClient.checkout.updateEmail(checkoutId, email);
      return JSON.parse(JSON.stringify(checkout));
    } catch (e) {
      throw Error('Ha ocurrido un error al añadir el email');
    }
  },
  addLineItemsToCheckout: async (checkoutId: string, lineItems): Promise<any> => {
    try {
      const checkout = await ShopifyClient.checkout.addLineItems(checkoutId, lineItems);
      return JSON.parse(JSON.stringify(checkout));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  removeLineItemsFromCheckout: async (checkoutId: string, lineItemsIds): Promise<any> => {
    try {
      const checkout = await ShopifyClient.checkout.removeLineItems(checkoutId, lineItemsIds);
      return JSON.parse(JSON.stringify(checkout));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  replaceLineItemsFromCheckout: async (checkoutId: string, lineItems): Promise<any> => {
    try {
      const checkout = await ShopifyClient.checkout.replaceLineItems(checkoutId, lineItems);
      return JSON.parse(JSON.stringify(checkout));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  updateLineItemsFromCheckout: async (checkoutId: string, lineItems): Promise<any> => {
    try {
      const checkout = await ShopifyClient.checkout.updateLineItems(checkoutId, lineItems);
      return JSON.parse(JSON.stringify(checkout));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  updateShippingAddressFromCheckout: async (checkoutId: string, shippingAddress): Promise<any> => {
    try {
      const checkout = await ShopifyClient.checkout.updateShippingAddress(checkoutId, shippingAddress);
      return JSON.parse(JSON.stringify(checkout));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  addDiscountToCheckout: async (checkoutId: string, discountCode: string): Promise<any> => {
    try {
      const checkout = await ShopifyClient.checkout.addDiscount(checkoutId, discountCode);
      return JSON.parse(JSON.stringify(checkout));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  removeDiscountFromCheckout: async (checkoutId: string): Promise<any> => {
    try {
      const checkout = await ShopifyClient.checkout.removeDiscount(checkoutId);
      return JSON.parse(JSON.stringify(checkout));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  addGiftCardsToCheckout: async (checkoutId: string, giftCardCodes: string[]): Promise<any> => {
    try {
      const checkout = await ShopifyClient.checkout.addGiftCards(checkoutId, giftCardCodes);
      return JSON.parse(JSON.stringify(checkout));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  },
  removeGiftCardFromCheckout: async (checkoutId: string, appliedGiftCardId: string): Promise<any> => {
    try {
      const checkout = await ShopifyClient.checkout.removeGiftCard(checkoutId, appliedGiftCardId);
      return JSON.parse(JSON.stringify(checkout));
    } catch (e) {
      throw Error('Ha ocurrido un error');
    }
  }
};
