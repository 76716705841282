import {
  ApolloClient,
  createHttpLink,
  gql,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: `https://${process.env.NEXT_PUBLIC_SHOPIFY_URL}/api/2022-01/graphql.json`
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_KEY
    }
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export const CREATE_CUSTOMER = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customerUserErrors {
        code
        field
        message
      }
      customer {
        id
      }
    }
  }
`;

export const CUSTOMER_LOGIN = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerUserErrors {
        code
        field
        message
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
    }
  }
`;

export const CHECKOUT_CUSTOMER_ASSOCIATE = gql`
  mutation checkoutCustomerAssociateV2($checkoutId: ID!, $customerAccessToken: String!) {
    checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
      checkout {
        id
      }
      customer {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  query getCustomer($customerToken: String!) {
    customer(customerAccessToken: $customerToken) {
      id
      firstName
      lastName
      displayName
      acceptsMarketing
      email
      phone
      tags
      updatedAt
      defaultAddress {
        address1
        address2
        city
        country
        countryCodeV2
        formattedArea
        zip
        province
        phone
        company
      }
      lastIncompleteCheckout {
        id
      }
      addresses(first:100) {
        edges {
          node {
            id
            name
            firstName
            lastName
            phone
            formatted
            address1
            address2
            city
            country
            countryCodeV2
            formattedArea
            zip
            province
            latitude
            longitude
          }
        }
      }
      orders(first:100) {
        edges {
          node {
            id
            customerUrl
            financialStatus
            fulfillmentStatus
            orderNumber
            phone
            processedAt
            shippingAddress {
              address1
              address2
              city
              country
              countryCodeV2
              formattedArea
              zip
              province
            }
            statusUrl
            subtotalPriceV2 {
              amount
              currencyCode
            }
            totalPriceV2 {
              amount
              currencyCode
            }
            totalRefundedV2 {
              amount
              currencyCode
            }
            totalShippingPriceV2 {
              amount
              currencyCode
            }
            totalTaxV2 {
              amount
              currencyCode
            }
            currentTotalTax {
              amount
              currencyCode
            }
            currentTotalPrice {
              amount
              currencyCode
            }
            currencyCode
            canceledAt
            cancelReason
          }
        }
      }
    }
  }
`;

export const GET_POLICIES = gql`
  query getCustomer($customerToken: String!) {
    customer(customerAccessToken: $customerToken) {
      id
      firstName
      lastName
      displayName
      acceptsMarketing
      email
      phone
      tags
      updatedAt
      defaultAddress {
        address1
        address2
        city
        country
        countryCodeV2
        formattedArea
        zip
        province
        phone
        company
      }
      lastIncompleteCheckout {
        id
      }
      addresses(first:100) {
        edges {
          node {
            id
            name
            firstName
            lastName
            phone
            formatted
            address1
            address2
            city
            country
            countryCodeV2
            formattedArea
            zip
            province
            latitude
            longitude
          }
        }
      }
      orders(first:100) {
        edges {
          node {
            id
            customerUrl
            financialStatus
            fulfillmentStatus
            orderNumber
            phone
            processedAt
            shippingAddress {
              address1
              address2
              city
              country
              countryCodeV2
              formattedArea
              zip
              province
            }
            statusUrl
            subtotalPriceV2 {
              amount
              currencyCode
            }
            totalPriceV2 {
              amount
              currencyCode
            }
            totalRefundedV2 {
              amount
              currencyCode
            }
            totalShippingPriceV2 {
              amount
              currencyCode
            }
            totalTaxV2 {
              amount
              currencyCode
            }
            currentTotalTax {
              amount
              currencyCode
            }
            currentTotalPrice {
              amount
              currencyCode
            }
            currencyCode
            canceledAt
            cancelReason
          }
        }
      }
    }
  }
`;

export const GET_ADVANCED_PRODUCT_DATA = gql`
  query getAdvancedProduct($handle: String!) {
    productByHandle(handle: $handle) {
      metafields(identifiers: [ 
        {namespace: "my_fields", key: "no_shippable"}, 
        {namespace: "my_fields", key: "og_image"}, 
        {namespace: "my_fields", key: "descripci_n_corta"}, 
      ]) { 
        key
        value
      }
    }
  }
`;
